.voice-call-content {
    margin-bottom: -12px;

    .voice-call-text {
      color: #2e3346;
      text-align: center;

      /* Title/List */
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 44px;
    }

    .voice-call-button {
      display: flex;
      width: 100%;
      padding: 14px;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #eee;

      color: #1a71ff;
      text-align: center;

      /* Title/Regular Page */
      font-family: Roboto;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }