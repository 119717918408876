@import '../scss/voice-call.scss';

body {
  margin: 0;
}

.channel-content {
  width: 100%;
  height: 95vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .channel-title {
    padding: 0 30px;
    margin: 28px 0 34px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .phone-number {
      color: #2e3346;
      font-feature-settings: 'clig' off, 'liga' off;

      /* Data/Large */

      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .code-tips {
      color: #1677ff;
      text-align: center;
      text-decoration: none;

      /* Title/Large Button */

      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .password-login-btn {
    width: 100%;
    text-align: center;
    height: 200px;
    line-height: 200px;

    font-size: 36px;
    color: #1a71ff;
    letter-spacing: 0;
    padding: 0 0 30px 0;
  }

  .content {
    flex: 1;
  }
  .arrow-back {
    margin-bottom: 96px;
    padding-top: 74px;
    margin-left: 30px;
  }
  .back-icon {
    font-size: 40px;
    font-weight: bold;
  }

  .country-show {
    position: absolute;
    top: 24px;
    right: 30px;
    width: 120px;
    height: 50px;
    margin-left: 30px;

    .country-show-abbreviation {
      font-size: 30px;
      color: #595f6d;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 5px;
      border: 1px solid #e3e7f1;
    }
  }

  .phone-box {
    margin-bottom: 150px;

    .form-btn {
      height: 90px;
      width: 654px;
      background-color: #4070f4;
      border-radius: 45px;
      font-size: 32px;
      color: #ffffff;
      line-height: 90px;
      text-align: center;
      margin: 0 auto;
    }

    .phone-error {
      color: #e61414;
      font-size: 28px;
    }
    .adm-form {
      padding: 0 30px;

      .register-phone {
        display: flex;
        flex-direction: row;
        position: relative;
        min-height: 96px;
      }

      .adm-list-default {
        border: none;

        .adm-list-body {
          border: none;
        }

        .adm-list-inner {
          margin-bottom: 0;
        }
      }

      .adm-form-footer {
        padding: 0;
      }

      .adm-list-item-description {
        .adm-form-item-footer {
          font-size: 28px;
        }
      }

      .adm-list-item {
        padding-left: 0;
        padding-bottom: 18px;

        .adm-list-item-content {
          padding: 0;
          width: 690px;
          border-bottom: none;
        }

        .adm-input-element {
          font-size: 32px;
          margin-left: 42px;
          margin-right: 150px;
          -webkit-appearance: none !important;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
        }

        input::placeholder {
          color: #999;
          font-size: 28px;
        }

        input:-internal-autofill-previewed,
        input:-internal-autofill-selected {
          -webkit-text-fill-color: #000;
          transition: background-color 5000s ease-out 0.5s;
        }
      }
    }

    .phone-input,
    .err-phone-input {
      height: 96px;
      width: 100%;
      background: #ffffff;
      border-radius: 48px;
      border: 2px solid #c6cad2;
      font-size: 28px;
      color: #333333;
    }

    .err-phone-input {
      border-color: #e61414;
    }

    .bottom-next {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: 150px;

      .otp-buttons {
        width: 100%;
        margin-top: 34px;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      .switch-btn-primary {
        width: 100%;
        height: 96px;
        border-radius: 56px;
        background: #1a71ff;
        color: #fff;

        /* Title/Large Button */

        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .switch-btn-default {
        width: 100%;
        height: 96px;
        border-radius: 56px;
        border: 2px solid #1a71ff;
        background: #fff;
        color: #1a71ff;

        /* Title/Large Button */

        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .switch-btn-text {
        width: 100%;
        height: 96px;
        padding: 24px 0;
        color: #1677ff;
        text-align: center;
        border: none;

        /* Title/Large Button */

        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .next-btn {
        height: 90px;
        width: 690px;
        background: #1a71ff;
        border-radius: 45px;
        font-size: 32px;
        color: #ffffff;
      }

      .next-tips {
        width: 100%;
        min-height: 60px;

        .next-tips-text {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        p {
          width: 100%;

          font-size: 24px;
          color: #858b9c;
          margin-bottom: 0;
          margin-top: 0;
          letter-spacing: -0.5px;
          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  .voice-call-content {
    margin-bottom: -12px;

    .voice-call-text {
      color: #2e3346;
      text-align: center;

      /* Title/List */

      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 44px;
    }

    .voice-call-button {
      display: flex;
      width: 100%;
      padding: 14px;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #eee;

      color: #1a71ff;
      text-align: center;

      /* Title/Regular Page */

      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
