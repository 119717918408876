body {
  margin: 0;
}

.mobilePage {
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: #fff;
  // padding-top: env(safe-area-inset-top) !important;

  .content {
    flex: 1;
  }
  .arrowBack {
    margin-bottom: 96px;
    padding-top: 74px;
    margin-left: 30px;
  }
  .backIcon {
    font-size: 40px;
    font-weight: bold;
  }

  .phoneNumber {
    letter-spacing: 0;
    
    font-size: 52px;
    color: #111111;
    font-weight: bold;
    min-height: 56px;
    line-height: 56px;
    padding-top: 96px;
  }

  .codeTip {
    box-sizing: border-box;
    letter-spacing: 0;
    text-align: left;
    
    font-size: 28px;
    color: #595f6d;
    margin-top: 18px;
    margin-bottom: 60px;
    min-height: 30px;
    line-height: 30px;
  }

  .phoneNumber,
  .codeTip {
    display: inline-block;
    padding-left: 30px;
  }

  .countryShow {
    position: absolute;
    top: 24px;
    right: 30px;
    width: 120px;
    height: 50px;
    margin-left: 30px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 5px;
      border: 1px solid #e3e7f1;
    }
  }

  .phoneBox {
    margin-bottom: 150px;

    .formBtn {
      height: 90px;
      width: 654px;
      background-color: #4070f4;
      border-radius: 45px;
      font-size: 32px;
      color: #ffffff;
      line-height: 90px;
      text-align: center;
      margin: 0 auto;
    }

    .phoneError {
      color: #e61414;
      font-size: 28px;
    }
    .adm-form {
      padding: 0 30px;

      .registerPhone {
        display: flex;
        flex-direction: row;
        position: relative;
        min-height: 96px;
      }
      .password-login-btn {
        height: 60px;
        
        font-size: 26px;
        color: #1a71ff;
        letter-spacing: 0;
      }
      .adm-list-default {
        border: none;

        .adm-list-body {
          border: none;
        }
      }

      .adm-form-footer {
        padding: 0;
      }

      .adm-list-item-description {
        .adm-form-item-footer {
          font-size: 28px;
        }
      }

      .adm-list-item {
        padding-left: 0;
        padding-bottom: 18px;

        .adm-list-item-content {
          padding: 0;
          width: 690px;
          border-bottom: none;
        }

        .adm-input-element {
          font-size: 32px;
          margin-left: 42px;
          margin-right: 150px;
          -webkit-appearance: none !important;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
        }

        input::placeholder {
          color: #999;
          font-size: 28px;
        }

        input:-internal-autofill-previewed,
        input:-internal-autofill-selected {
          -webkit-text-fill-color: #000;
          transition: background-color 5000s ease-out 0.5s;
        }
      }
    }

    .phoneInput,
    .errPhoneInput {
      height: 96px;
      width: 100%;
      background: #ffffff;
      border-radius: 48px;
      border: 2px solid #c6cad2;
      font-size: 28px;
      color: #333333;
    }

    .errPhoneInput {
      border-color: #e61414;
    }
  }

  .bottomNext {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 44px;
    align-items: center;
    margin-top: 120px;
    width: 100%;
    min-height: 150px;

    .nextBtn {
      height: 90px;
      width: 690px;
      background: #1a71ff;
      border-radius: 45px;
      font-size: 32px;
      color: #ffffff;
    }

    .nextTip {
      width: 100%;
      padding: 0 25px 0 30px;
      margin-top: 24px;
      min-height: 60px;

      p {
        width: 100%;
        
        font-size: 24px;
        color: #858b9c;
        margin-bottom: 0;
        letter-spacing: -0.5px;
        a {
          text-decoration: none;
        }
      }
    }
  }
}
