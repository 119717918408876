body {
  margin: 0;
}

*:not(input) {
  -webkit-user-select: none;
}

.code-page {
  padding: 0 30px 0 30px !important;
  box-sizing: border-box;
  background-color: #fff;
  min-height: 100vh;

  .arrowBack {
    padding-top: 74px;
    margin-bottom: 96px;
  }
  .backIcon {
    font-size: 40px;
    font-weight: bold;
    text-align: left;
  }

  .codeTip {
    color: #2e3346;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Data/Large */
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 28px;
  }

  .codeNumberTip {
    color: #2e3346;

    /* Title/Tab */
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;

    .sms-code-container-tips-number {
      color: #2e3346;

      /* Title/Tab-B */
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .codeNumber {
    color: #111111;
    margin-bottom: 30px;
    font-size: 36px;
  }

  .inputList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .inputValue {
      display: inline-block;
      width: 100px;
      height: 100px;
      border: 2px solid #c6cad2;
      border-radius: 18px;
      text-align: center;
      line-height: 100px;
      font-size: 60px;
      color: #111111;
    }

    .wrong {
      border: 2px solid #e61414;
      color: #e61414 !important;
    }
  }

  .inputBox {
    position: relative;
    height: 92px;
    margin: 60px 0 10px 0;

    .captcha {
      .code-box {
        display: flex;
        justify-content: center;
        align-items: center;

        .item-content {
          width: 95px;
          height: 95px;
          border-radius: 24px;
          border: 1px solid #ccc;
          background: #fff;
          font-size: 48px;
          line-height: 95px;
          text-align: center;

          &.item-content-active::before {
            position: absolute;
            top: 25%;
            left: 50%;
            display: block;
            width: 2px;
            height: 50%;
            margin-left: -1px;
            background-color: #1a71ff;
            animation: rc-captcha-input-flash steps(2) 1s infinite;
            content: '';
          }
        }
      }

      .input-box-wrap {
        position: absolute;
        left: -9999px;
      }

      &.captcha-theme-line {
        .code-box {
          .item-content {
            position: relative;
            color: #161823;
            border-bottom: 1px solid rgba(22, 24, 35, 0.5);
          }
        }
      }

      &.captcha-theme-box {
        .code-box {
          .item-content {
            color: #121212;

            &.item-content-active {
              position: relative;
              border-color: #1a71ff;
            }
          }
        }
      }

      @keyframes rc-captcha-input-flash {
        0% {
          visibility: hidden;
        }

        100% {
          visibility: visible;
        }
      }
    }
  }

  .countBox {
    display: flex;
    flex-direction: row;
    align-items: center;

    .notReceive {
      color: #858b9c;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .button {
      color: #1a71ff;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .bottomBtn {
    width: 100%;
    height: 90px;
    background: #1a71ff;
    border-radius: 45px;
    color: #fff;
    text-align: center;
    font-size: 36px;
    margin-top: 60px;
  }
}
