.overlay-content {
  position: absolute;
  text-align: center;
  top: 20%;
  height: 449px;
  width: 630px;
  left: calc(50% - 315px);
  --adm-button-background-color: var(--adm-color-primary);
  --adm-button-text-color: #fff;
  .adm-card {
    border-radius: 24px;
    .adm-card-header:not(:last-child) {
      border-bottom: none;
    }
  }
  .close {
    margin-top: 40px;
  }
}
