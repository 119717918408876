@import '../../../styles/index.scss';

.createPage,
.bindPage {
  padding: 0 30px 0 30px !important;
  box-sizing: border-box;
  background-color: #fff;
  min-height: 100vh;

  .arrowBack {
    padding-top: 74px;
    margin-bottom: 96px;
  }
  .backIcon {
    font-size: 40px;
    font-weight: bold;
    text-align: left;
  }
  .createTitle {
    font-size: 52px;
    color: #111111;
    font-weight: 800;
    margin-bottom: 113px;
    padding-top: 96px;
  }
  .avatarBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 160px;
    .avatar {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin-bottom: 35px;
    }
    .text {
      height: 36px;
      font-size: 60px;
      color: #595f6d;
      line-height: 36px;
    }
  }
  .personalInformation {
    margin-bottom: 90px;
    height: 164px;
    .title {
      min-height: 35px;
      line-height: 35px;
      font-size: 32px;
      color: #595f6d;
      margin-bottom: 20px;
    }
    .information {
      height: 36px;
      line-height: 36px;
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      word-break: break-all;
      img {
        width: 36px;
        height: 36px;
        margin-right: 18px;
      }
      .emailImg {
        width: 42px;
      }
      .phone,
      .email {
        font-size: 32px;
        color: #333333;
        @include clamp-lines(1);
      }
      &:first-of-type {
        margin-bottom: 18px;
      }
    }
  }
  .bottomBox {
    width: 100%;
    .adm-button {
      width: 100%;
      min-height: 96px;
      border-radius: 48px;
    }
  }
}
