body {
  margin: 0;
}

.loginPage {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  background-color: #fff;

  .content {
    position: relative;

    .loginBack {
      padding: 0 30px;
      margin: 28px 0 34px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        color: #2e3346;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Data/Large */
        
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .sign-up-text {
        color: #1677ff;
        text-align: center;
        text-decoration: none;

        /* Title/Large Button */
        
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .codeTip {
    box-sizing: border-box;
    letter-spacing: 0;
    text-align: left;
    
    font-size: 52px;
    color: #595f6d;
    margin-top: 18px;
    margin-bottom: 60px;
    min-height: 30px;
    line-height: 30px;
  }

  .phoneNumber,
  .codeTip {
    display: inline-block;
    padding-left: 30px;
  }
  .errorBox {
    color: rgb(255, 49, 65);
    font-size: 28px;
    padding-left: 22px;
  }
  .phoneBox {
    .adm-form {
      .adm-list-default {
        border: none;
        .adm-list-body {
          border: none;
        }
      }

      .adm-list-item-content {
        border-top: none;
      }

      .countryShow {
        position: absolute;
        top: 50px;
        right: 60px;
        width: 120px;
        height: 50px;
        margin-left: 30px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }

      .LoginPassword {
        display: flex;
        flex-direction: row;
        position: relative;
        min-height: 96px;
        .adm-input {
          margin-right: 60px;
        }
      }

      .adm-list-item-description {
        .adm-form-item-footer {
          font-size: 28px;
          margin-top: 20px;
        }
      }

      .adm-list-item {
        padding-left: 24px;
        .adm-list-item-content {
          border-bottom: none;
          width: 690px;
          padding: 12px 0;
        }
        .adm-input-element {
          font-size: 32px;
          margin-left: 42px;
          -webkit-appearance: none !important;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
        }
        input:-internal-autofill-previewed,
        input:-internal-autofill-selected {
          -webkit-text-fill-color: #000;
          transition: background-color 5000s ease-out 0.5s;
        }
      }

      .adm-form-footer {
        padding: 24px;
      }

      .eye {
        position: absolute;
        top: 50%;
        right: 80px;
        height: 42px;
        width: 42px;
        transform: translateY(-50%);
      }
    }

    .phoneInput {
      height: 96px;
      width: 690px;
      background: #ffffff;
      border-radius: 48px;
      border: 2px solid #c6cad2;
      font-size: 32px;
      color: #333333;
      padding-right: 42px;
    }
  }

  .bottomNext {
    margin-bottom: 44px;
    text-align: center;
    width: 100%;
    margin-top: 34px;

    .nextBtn {
      height: 90px;
      width: 690px;
      background: #1a71ff;
      border-radius: 45px;
      font-size: 36px;
      color: #ffffff;
    }

    .nextTip {
      width: 100%;
      padding: 0 25px 0 30px;
      margin-top: 24px;

      p {
        width: 100%;
        
        font-size: 48px;
        color: #858b9c;
        margin-bottom: 0;
      }
    }
  }

  .backIcon {
    font-size: 40px;
    font-weight: bold;
    text-align: left;
  }
  .LoginWithSMS {
    width: 100%;
    text-align: center;
    color: #4070f4;
    font-size: 32px;
    margin-top: 30px;
  }

  .otp-button {
    margin-top: 50vh;
  }
}

.adm-list-item-content-main {
  padding: 0;
}
