.machinePage {
  padding: 0 30px 0 30px !important;
  background-color: #fff;
  min-height: 100vh;

  .arrowBack {
    padding-top: 74px;
    margin-bottom: 162px;
  }
  .backIcon {
    font-size: 40px;
    font-weight: bold;
  }

  .shield {
    padding-top: 162px;
    text-align: center;
    img {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background-color: #ccc;
    }
  }

  .tipTop,
  .tipBottom {
    min-height: 42px;
    
    font-weight: bold;
    font-size: 40px;
    color: #111111;
    letter-spacing: 0;
    text-align: center;
  }
  .tipTop {
    margin-top: 60px;
  }

  .tipBottom {
    margin-bottom: 60px;
  }

  .Hslider {
    width: 100%;
    .nc_wrapper {
      width: 100% !important;
    }
    .nc-container .nc_scale .nc_ok,
    .nc-container .nc_scale .nc_bg {
      background-color: #d4f3eb;
      border-radius: 12px;
    }
    .nc-container .nc_scale .btn_slide {
      background-image: linear-gradient(90deg, #17ca9d 0%, #02af83 100%);
      border-radius: 12px;
    }
    .nc-container .nc_scale .scale_text {
      background-color: #d4f3eb;
      border-radius: 12px;
    }
    .nc-container .nc_scale .btn_ok {
      background-image: linear-gradient(90deg, #17ca9d 0%, #02af83 100%);
      border-radius: 12px;
      color: #fff;
    }
    .nc_scale {
      border-radius: 12px;
    }
    .nc-lang-cnt {
      font-size: 28px;
      color: #ffffff;
    }
  }
}
