:root:root {
  --adm-button-border-radius: 20px;
}

.adm-steps {
  .adm-step-icon-container {
    background-color: #eff1f7 !important;
  }
}

@mixin clamp-lines($num: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $num;
  overflow: hidden;
  text-overflow: ellipsis;
}
