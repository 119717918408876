.email-tag-group {
  margin-bottom: 24px;

  .email-tag-group-tag {
    border-radius: 24px;
    cursor: pointer;
    font-size: 24px;
    border: 1px solid #858b9c;
    padding: 10px 8px;
  }
}
