body {
  margin: 0;
}

.downloadPage {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding-top: 360px;
  box-sizing: border-box;

  .lzdLogo {
    height: 256px;
    width: 256px;
    background-image: url('https://img.alicdn.com/imgextra/i3/O1CN01VNLqRN1x1vBiuJdmd_!!6000000006384-55-tps-300-300.svg');
    background-size: 100%;
  }

  .continueText {
    min-height: 57px;
    font-size: 56px;
    color: #111111;
    letter-spacing: 0;
    font-weight: bold;
    margin-top: 42px;
    line-height: 57px;
    text-align: center;
  }

  .experienceTip {
    width: 100%;
    min-height: 60px;
    font-size: 32px;
    color: #595f6d;
    text-align: center;
    margin-bottom: 60px;
    margin-top: 8px;
  }

  .downloadBtn {
    min-height: 96px;
    width: 690px;
    background-color: #1a71ff;
    border-radius: 48px;
    text-align: center;
    line-height: 96px;
    color: #fff;
    font-size: 32px;
  }
}
