.passwordPage {
  padding: 0 30px 0 30px !important;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fff;

  .arrowBack {
    padding-top: 74px;
    margin-bottom: 96px;
  }

  .backIcon {
    font-size: 40px;
    font-weight: bold;
  }

  .titleOne {
    min-height: 56px;
    
    font-size: 52px;
    color: #111111;
    letter-spacing: 0;
    line-height: 56px;
    margin-bottom: 18px;
    font-weight: bold;
    padding-top: 96px;
  }

  .titleTwo {
    min-height: 30px;
    
    font-size: 28px;
    color: #595f6d;
    letter-spacing: -0.5px;
    line-height: 30px;
    margin-bottom: 60px;
  }

  .formBox {
    width: 100%;

    .formBtn {
      width: 100%;
      height: 96px;
      background-color: #4070f4;
      border-radius: 48px;
      font-size: 32px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 52px;
    }
    .adm-form {
      .passwordTwo {
        display: flex;
        flex-direction: row;
        position: relative;
        // align-items: center;
        min-height: 96px;
        .adm-list-item {
          .adm-list-item-content {
            width: 690px;
          }
          .adm-input {
            margin-right: 50px;
          }
        }
      }
      .passwordError {
        margin-bottom: 18px;
      }

      .adm-list-default {
        border: none;
        .adm-list-body {
          border: none;
          &-inner {
            margin-top: 0;
          }
        }
      }

      .adm-form-footer {
        padding: 0;
      }
      .adm-list-item-description {
        .adm-form-item-footer {
          font-size: 28px;
        }
      }
      .adm-list-item {
        &.form-item-seller-type {
          line-height: normal;
        }
        padding-left: 0;
        padding-bottom: 18px;
        .adm-list-item-content {
          padding: 0;
          border: none;
        }
        .adm-radio {
          --icon-size: 36px;
          &:first-of-type {
            margin-right: 36px;
          }
        }
        .adm-input-element {
          font-size: 32px;
          margin-left: 42px;
          -webkit-appearance: none !important;
          // -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
          -webkit-tap-highlight-color: transparent !important;
          &:focus {
            outline: none;
            border: none;
          }
        }
        input::placeholder {
          color: #999;
          font-size: 32px;
        }
        input:-internal-autofill-previewed,
        input:-internal-autofill-selected {
          -webkit-text-fill-color: #000;
          transition: background-color 5000s ease-out 0.5s;
        }
      }
      .agent-support-text {
        margin-top: 30px;
      }
    }

    .eye {
      position: absolute;
      top: 26px;
      right: 30px;
      height: 42px;
      width: 42px;
    }
    .identity {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 18px;
      .identitytip {
        width: 650px;
        
        font-size: 28px;
        color: #858b9c;
        line-height: 32px;
        letter-spacing: -0.5px;
      }
      .iconImg {
        width: 24px;
        height: 24px;
        margin-top: 10px;
        margin-right: 12px;
      }
    }
    .password {
      height: 96px;
      width: 100%;
      background: #ffffff;
      border-radius: 48px;
      border: 2px solid #c6cad2;
      font-size: 28px;
      color: #333333;
      padding-right: 42px;
      // margin-bottom: 30px;
    }
  }

  .successtip,
  .errortip {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 28px;
    margin-bottom: 18px;
    letter-spacing: -0.5px;

    img {
      width: 24px;
      height: 24px;
      margin-right: 13px;
    }
  }

  .successtip {
    color: #858b9c;
  }

  .errortip {
    color: #e61414;
  }
}
